<template>
  <!-- 授信额度 -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="店铺关系记录"
    ></el-page-header>
    <div class="select-container">
      <div class="select">
        <div class="selectVal">
          <div class="text">被授信店铺</div>
        <el-select class="selectValue" v-model="selectValue" placeholder="请选择搜索方式">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
          <el-input
            placeholder="请输入店铺名称"
            v-model="inputData"
            clearable
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          class="search"
          icon="el-icon-search"
          :disabled="buttonDisabled"
          @click="searchButton"
          >搜索</el-button
        >
        <el-button type="success" @click="allProduct">重置</el-button>
      </div>
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="60%"
          >
          </el-table-column>
          <el-table-column prop="creditStoreName" label="被授信店铺" width="230%">
          </el-table-column>
          <el-table-column prop="money" label="授信额度" width="160%">
          </el-table-column>
          <el-table-column prop="nowConsume" label="消费金额" width="160%">
          </el-table-column>
          <el-table-column prop="limit_money" label="剩余额度" width="160%">
          </el-table-column>
          </el-table-column>
          <el-table-column prop="vipcard" label="会员卡号" width="240%">
          </el-table-column>
          <el-table-column prop="vipname" label="会员名称" width="200%">
          </el-table-column>
          <el-table-column prop="createTime" label="消费清单" width="300%">
            <template slot-scope="scope">
              <el-table
                :data="scope.row.createTime"
                stripe
                style="width: 100%"
              >
                <el-table-column type="index"></el-table-column>
                <el-table-column prop="name" label="商品"></el-table-column>
                <el-table-column prop="num" label="数量"></el-table-column>
                <el-table-column prop="price" label="单价"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="consume_time" label="更新时间" width="220%">
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      options: [
        {
          value: 1,
          label: "店铺名称",
        },
        {
          value: 2,
          label: "会员名称",
        },
        {
          value: 3,
          label: "会员卡号",
        },
      ],
      tableData: "",
      selectValue: "",
      value: "title",
      inputData: "",
      selectVal: "",
      buttonDisabled: true,
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      files_count: 5,
      fileList: [],
      searchFile: "",
      totalNum: 0,
      page_num: 1,
      myStoreKey: "",
    };
  },
  created() {
    this.myStoreKey = secret.Decrypt(localStorage.getItem("user_key"));
    this.initVal();
  },
  // 监听器
  watch: {
    inputData: function () {
      this.buttonDisabled =
        this.inputData !== "" || this.inputData ? false : true;
    },
  },
  methods: {
    initVal() {
      const storeKey = this.myStoreKey;
      let pageNum = 1;
      let selectLog = true;
      axios
        .post(this.GLOBAL.api + "/store", { selectLog, pageNum, storeKey })
        .then((res) => {
          this.loading = false;
          this.totalNum = res.data.total[0].count;
          this.tableData = res.data.val;
          res.data.val.forEach((ele,i) => {
            this.tableData[i].createTime = JSON.parse(ele.consume_list);
          });
        });
          // console.log('----',this.tableData);
    },
    goBack() {
      this.$router.push("/homepage");
    },
    handleCurrentChange(val) {
      const storeKey = this.myStoreKey;
      let pageNum = val;
      this.page_num = val;
      let selectLog = true;
      axios
        .post(this.GLOBAL.api + "/store", { selectLog, pageNum, storeKey })
        .then((res) => {
          this.loading = false;
          this.totalNum = res.data.total[0].count;
          this.tableData = res.data.val;
        });
    },
    searchButton() {
      let selectText = this.inputData;
      let selectStyle = this.selectValue;
      let storeKey = this.myStoreKey;
      let serchLog = true;
      axios
        .post(this.GLOBAL.api + "/store", {
          selectText,
          selectStyle,
          storeKey,
          serchLog,
        })
        .then((res) => {
          this.totalNum = res.data.total[0].count;
          this.tableData = res.data.val;
        });
    },
    allProduct() {
      this.initVal();
      this.inputData = "";
      this.selectValue = "";
    },
    // contractMoney
    csllStyle(row) {
      // 监听tabel数据进行操作
      if (row.row.contractMoney < 0) {
        return "backgroundColor:rgb(167 41 41);color:#FFF";
      }
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  width: 1500px;
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 35%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.search {
  margin-left: 10px;
}
.text {
  width: 35%;
  height: 100%;
  line-height: 250%;
}
.el-table {
  margin-top: 20px;
}
.selectValue {
  width: 300px;
  margin-right: 10px;
}
</style>